import { red } from '@material-ui/core/colors'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import 'typeface-montserrat'
import 'typeface-roboto'

// A custom theme for this app
let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#000',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    h2: {
      fontFamily: 'montserrat',
    }
  }
})
theme = responsiveFontSizes(theme)

export default theme